import React from 'react';
import ReactDOM from 'react-dom/client';
import {
	BrowserRouter,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import "./assets/vendor/pl-dashboard.css";
import "./assets/vendor/nucleo/css/nucleo.css";
import "bootstrap/dist/css/bootstrap.min.css";

import App from './App';
import reportWebVitals from './reportWebVitals';
import { PostHogProvider } from 'posthog-js/react'

import './index.css';

const options = {
	api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

Sentry.init({
	dsn: "https://7b4fb3f64d727c6f1e80ea714720dda1@o4507402539171840.ingest.us.sentry.io/4507402901127168",
	integrations: [
		Sentry.browserTracingIntegration(),
		Sentry.replayIntegration(),
	],
	environment: process.env.REACT_APP_NODE_ENV,
	// Performance Monitoring
	tracesSampleRate: 1.0, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<BrowserRouter>
		<PostHogProvider
			apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
			options={options}
		>
			<App />
		</PostHogProvider>

	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
